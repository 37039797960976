import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Row, Col, Alert, Spin } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Signer, ConfirmOptions, Connection, Transaction, TransactionSignature, PublicKey } from '@solana/web3.js'
import Wallet from '@project-serum/sol-wallet-adapter'
import { TokenListContainer, TokenListProvider } from '@solana/spl-token-registry'
import { Provider } from '@project-serum/anchor'
import Swap from '../swap/index'
import { MarketProvider, getTradePageUrl } from '../utils/dex-markets'
import { DexLabMarketV2Info } from '../utils/types'
import DexlabMarketAPI from '../utils/client/dexlabMarketApiConnector'
import queryString from 'query-string'
import { useWallet } from '../utils/wallet'
import { notify } from '../utils/notifications'
import { copyToClipboard } from '../utils/utils'
import { REACT_APP_SWAP_REFERRAL_FEES_ADDRESS } from '../application'
import { useConnection } from '../utils/connection'
import { LoadingOutlined } from '@ant-design/icons'
import {
  // FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  // FacebookIcon,
  RedditIcon,
} from 'react-share'
import { DXL_MINT, SOL_MINT, USDC_MINT, WRAPPED_SOL_MINT } from '../swap/utils/pubkeys'

const SWAP_PAGE_URL = 'https://openbook.dexlab.space/#/swap'
const SHARE_DEFAULT_MESSAGE = 'Dexlab Orderbook Based Swap\n#dexlab #swap #DXL #solna'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`

const opts: ConfirmOptions = {
  preflightCommitment: 'recent',
  commitment: 'recent',
}

function InnerPage({ allV2Markets, wallet }) {
  const { t: trText } = useTranslation()
  const connection = useConnection()
  const [tokenList, setTokenList] = useState<TokenListContainer | null>(null)
  const [isSwapLoading, setIsSwapLoading] = useState(false)
  const [selectFromTokenMint, setSelectFromTokenMint] = useState<string | null>(USDC_MINT.toBase58())
  const [selectToTokenMint, setSelectToTokenMint] = useState<string | null>(DXL_MINT.toBase58())
  const [selectRouteMarket, setSelectRouteMarket] = useState<string | null>(null)
  const [selectTokenText, setSelectTokenText] = useState<string | null>(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const { search } = useLocation()
  const parsed = queryString.parse(search)
  const defaultMarketId = parsed.id ? (parsed.id as string) : undefined

  useEffect(() => {
    new TokenListProvider().resolve().then((r) => {
      setTokenList(r)
    })
  }, [setTokenList])

  useEffect(() => {
    if (!_.isEmpty(allV2Markets) && selectFromTokenMint && selectToTokenMint) {
      if (isFirstLoad && parsed.id) {
        const findMakret = allV2Markets.find((f) => f.address === parsed.id)
        if (findMakret) {
          setSelectRouteMarket(findMakret.address)
          setSelectTokenText(
            `${findMakret.base !== 'DXL' ? 'Dexlab ' : ''}[${
              findMakret.nameEn
            }] Token Swap\n#dexlab #swap #${findMakret.nameEn.replace(/(\s*)/g, '')} #${findMakret.base} #solna`,
          )
        }
      } else {
        const findBaseTokenMint =
          selectToTokenMint === SOL_MINT.toBase58() ? WRAPPED_SOL_MINT.toBase58() : selectToTokenMint
        const findMakret = allV2Markets.find(
          (f) => f.baseMint === selectFromTokenMint && f.quoteMint === findBaseTokenMint,
        )
        if (findMakret) {
          setSelectRouteMarket(findMakret.address)
          setSelectTokenText(
            `${findMakret.base !== 'DXL' ? 'Dexlab ' : ''}[${
              findMakret.nameEn
            }] Token Swap\n#dexlab #swap #${findMakret.nameEn.replace(/(\s*)/g, '')} #${findMakret.base} #solna`,
          )
        } else {
          const findRevMakret = allV2Markets.find(
            (f) => f.baseMint === findBaseTokenMint && f.quoteMint === selectFromTokenMint,
          )
          if (findRevMakret) {
            setSelectRouteMarket(findRevMakret.address)
            setSelectTokenText(
              `${findRevMakret.base !== 'DXL' ? 'Dexlab ' : ''}[${
                findRevMakret.nameEn
              }] Token Swap\n#dexlab #swap #${findRevMakret.nameEn.replace(/(\s*)/g, '')} #${
                findRevMakret.base
              } #solna`,
            )
          } else {
            setSelectRouteMarket(null)
            setSelectTokenText(null)
          }
        }
      }
    }
    if (isFirstLoad) {
      setIsFirstLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFromTokenMint, selectToTokenMint])

  const provider = new NotifyingProvider(connection, wallet as Wallet, opts, (tx, err) => {
    setIsSwapLoading(false)
    if (err) {
      notify({
        message: `Error: ${err.toString()}`,
        type: 'error',
      })
    } else {
      notify({ message: 'Transaction sent', type: 'success', txid: tx })
    }
  })

  function selectTokenInfo(fromMint?: string | null, toMint?: string | null) {
    if (fromMint) {
      setSelectFromTokenMint(fromMint === WRAPPED_SOL_MINT.toBase58() ? SOL_MINT.toBase58() : fromMint)
    }
    if (toMint) {
      setSelectToTokenMint(toMint === WRAPPED_SOL_MINT.toBase58() ? SOL_MINT.toBase58() : toMint)
    }
  }

  return (
    tokenList && (
      <>
        <div>
          {isSwapLoading ? (
            <Alert
              type="success"
              style={{ marginBottom: '5px' }}
              message={
                <>
                  <Spin style={{ marginRight: '6px' }} indicator={antIcon} />
                  {trText('swap_progress_message')}
                </>
              }
            />
          ) : null}
          <Swap
            containerStyle={{ backgroundColor: '#131722', color: '#ffffff' }}
            contentStyle={{ color: '#ffffff' }}
            allV2Markets={allV2Markets}
            defaultMarketId={defaultMarketId}
            provider={provider}
            tokenList={tokenList}
            selectTokenInfo={selectTokenInfo}
            referral={new PublicKey(REACT_APP_SWAP_REFERRAL_FEES_ADDRESS)}
            setIsSwapLoading={setIsSwapLoading}
          />
          <div style={{ textAlign: 'center', marginTop: '15px', marginBottom: '60px' }}>
            <TwitterShareButton
              url={selectRouteMarket ? `${SWAP_PAGE_URL}?id=${selectRouteMarket}` : SWAP_PAGE_URL}
              title={selectTokenText ? selectTokenText : `${SHARE_DEFAULT_MESSAGE}\n`}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <TelegramShareButton
              style={{ marginLeft: '6px' }}
              url={selectRouteMarket ? `${SWAP_PAGE_URL}?id=${selectRouteMarket}` : SWAP_PAGE_URL}
              title={selectTokenText ? selectTokenText : `${SHARE_DEFAULT_MESSAGE}`}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <WhatsappShareButton
              style={{ marginLeft: '6px' }}
              url={selectRouteMarket ? `${SWAP_PAGE_URL}?id=${selectRouteMarket}` : SWAP_PAGE_URL}
              title={selectTokenText ? selectTokenText : `${SHARE_DEFAULT_MESSAGE}`}
              separator=":: "
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            {/* <FacebookShareButton
              style={{ marginLeft: '6px' }}
              url={selectRouteMarket ? `${SWAP_PAGE_URL}?id=${selectRouteMarket}` : SWAP_PAGE_URL}
              title={selectTokenText ? selectTokenText : `${SHARE_DEFAULT_MESSAGE}`}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton> */}
            <RedditShareButton
              style={{ marginLeft: '6px' }}
              url={selectRouteMarket ? `${SWAP_PAGE_URL}?id=${selectRouteMarket}` : SWAP_PAGE_URL}
              title={selectTokenText ? selectTokenText : `${SHARE_DEFAULT_MESSAGE}`}
              windowWidth={660}
              windowHeight={460}
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
            <CopyOutlined
              style={{ marginLeft: '6px', fontSize: '25px' }}
              onClick={() => {
                copyToClipboard(selectRouteMarket ? `${SWAP_PAGE_URL}?id=${selectRouteMarket}` : SWAP_PAGE_URL)
                notify({
                  message: `Copy success!`,
                  type: 'success',
                })
              }}
            />
          </div>
        </div>
      </>
    )
  )
}
export default function SwapPage() {
  document.title = `Swap - The best DEX platform on SOLANA.`
  const { marketAddress } = useParams()

  const { wallet } = useWallet()
  const [allV2Markets, setAllV2Markets] = useState<DexLabMarketV2Info[]>([])

  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress))
    }
  }, [marketAddress])
  const history = useHistory()
  function setMarketAddress(address) {
    history.push(getTradePageUrl(address))
  }

  useEffect(() => {
    async function getAllMarkets() {
      const response = await DexlabMarketAPI.getAllMarketsV2()
      if (response) {
        setAllV2Markets(response)
      } else {
        history.push(`/error`)
      }
    }
    getAllMarkets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper style={{ flex: 1, paddingTop: 6 }}>
      <div className="col-xl-12 col-lg-12 col-md-12 col-xxl-12" style={{ marginBottom: '6px', textAlign: 'center' }}>
        <Alert
          type="success"
          message={
            <>
              Dexlab platform is open! This page service is available in Dexlab platform.{' '}
              <a href="https://www.dexlab.space/swap" target="_blank" rel="noopener noreferrer">
                Go to Dexlab platform
              </a>
            </>
          }
        />
      </div>
      <Row style={{ paddingTop: 60 }} justify="center">
        <Col>
          {!_.isEmpty(allV2Markets) && wallet ? (
            <MarketProvider
              allV2Markets={allV2Markets}
              marketAddress={marketAddress}
              setMarketAddress={setMarketAddress}
            >
              <InnerPage allV2Markets={allV2Markets} wallet={wallet} />
            </MarketProvider>
          ) : (
            <Spin />
          )}
        </Col>
      </Row>
    </Wrapper>
  )
}

// Cast wallet to AnchorWallet in order to be compatible with Anchor's Provider class
interface AnchorWallet {
  signTransaction(tx: Transaction): Promise<Transaction>
  signAllTransactions(txs: Transaction[]): Promise<Transaction[]>
  publicKey: PublicKey
}

// Custom provider to display notifications whenever a transaction is sent.
//
// Note that this is an Anchor wallet/network provider--not a React provider,
// so all transactions will be flowing through here, which allows us to
// hook in to display all transactions sent from the `Swap` component
// as notifications in the parent app.
class NotifyingProvider extends Provider {
  // Function to call whenever the provider sends a transaction;
  private onTransaction: (tx: TransactionSignature | undefined, err?: Error) => void

  constructor(
    connection: Connection,
    wallet: Wallet,
    opts: ConfirmOptions,
    onTransaction: (tx: TransactionSignature | undefined, err?: Error) => void,
  ) {
    const newWallet = wallet as AnchorWallet
    super(connection, newWallet, opts)
    this.onTransaction = onTransaction
  }

  async send(
    tx: Transaction,
    signers?: Array<Signer | undefined>,
    opts?: ConfirmOptions,
  ): Promise<TransactionSignature> {
    try {
      const txSig = await super.send(tx, signers, opts)
      this.onTransaction(txSig)
      return txSig
    } catch (err: any) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err)
      }
      return ''
    }
  }

  async sendAll(
    txs: Array<{ tx: Transaction; signers: Array<Signer | undefined> }>,
    opts?: ConfirmOptions,
  ): Promise<Array<TransactionSignature>> {
    try {
      const txSigs = await super.sendAll(txs, opts)
      txSigs.forEach((sig) => {
        this.onTransaction(sig)
      })
      return txSigs
    } catch (err: any) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err)
      }
      return []
    }
  }
}
