import React from 'react'
import FloatingElement from '../../componentsv2/layout/FloatingElement'
import styled from 'styled-components'
import { Space, Card, Typography, Tooltip } from 'antd'
import { AppstoreAddOutlined, FolderAddOutlined, QuestionCircleOutlined } from '@ant-design/icons'

const Wrapper = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
`

const { Title } = Typography

export default function DexAddExistingMarketPage() {
  document.title = `List Your Market - The best DEX platform on SOLANA.`
  return (
    <Wrapper>
      <FloatingElement>
        <div style={{ textAlign: 'center', marginTop: '35px' }}>
          <Title level={3}>
            <span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>List Your Market</span>
          </Title>
          <Space align="center" style={{ textAlign: 'center' }}>
            <a href={'https://bit.ly/3uTUUKs'} target="_blank" rel="noopener noreferrer">
              <Card
                title={
                  <>
                    <AppstoreAddOutlined style={{ fontSize: '25px' }} /> Create OpenBook DEX Market ID
                  </>
                }
                style={{ width: 500, height: '250px', cursor: 'pointer' }}
              >
                <p>Create a OpenBook DEX Market ID.</p>
                <p>You can list on Dexlab with the generated Market ID.</p>
                <p>
                  <b>This market is no longer recommended.</b>
                </p>
                <div style={{ marginTop: '70px', fontSize: '13px' }}>
                  <span>
                    Transaction Fee: 3 SOL ~ 5 SOL
                    <Tooltip title="Fees are Solana transaction fees.">
                      {' '}
                      <QuestionCircleOutlined style={{ fontSize: '15px' }} />
                    </Tooltip>
                  </span>
                </div>
              </Card>
            </a>
            <a
              href={'https://forms.monday.com/forms/52c5e38dbd3d176332ba7d6cb81bc38c?r=use1'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card
                title={
                  <>
                    <FolderAddOutlined style={{ fontSize: '25px' }} /> Add Market Listing
                  </>
                }
                style={{ width: 500, height: '250px', cursor: 'pointer' }}
              >
                <p>Submit your application according to the form.</p>
                <p>Listed immediately after review (takes up to 3 weeks)</p>
              </Card>
            </a>
          </Space>
        </div>
      </FloatingElement>
    </Wrapper>
  )
}
