export const DEXLAB_MARKETS = [
  {
    address: '8BnEgHoWFysVcuFFX7QztDmzuH8r5ZFvyP3sYwn1XTh6',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SOL',
    baseMint: 'So11111111111111111111111111111111111111112',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'SOL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Solana',
    nameCn: null,
    nameKr: '솔라나',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/sol/symbol.png?raw=true',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/solana',
        name: 'TWITTER',
      },
      {
        url: 'https://solana.com',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://github.com/solana-labs/',
        name: 'GitHub',
      },
      {
        url: 'https://docs.solana.com/',
        name: 'Document',
      },
      {
        url: 'https://discord.com/invite/pquxPsq',
        name: 'Discord',
      },
      {
        url: 'https://t.me/solana',
        name: 'Telegram',
      },
    ],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'solana',
    tvWidgetChartSymbol: 'SOLUSDT',
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: 'FZxi3yWkE5mMjyaZj6utmYL54QQYfMCKMcLaQZq4UwnA',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'ETH',
    baseMint: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'ETH/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Ethereum',
    nameCn: null,
    nameKr: '이더리움',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/eth/symbol.png?raw=true',
    nftImageUrl: null,
    community: [],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'ethereum',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: 'DZjbn4XC8qoHKikZqzmhemykVzmossoayV9ffbsUqxVj',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RAY',
    baseMint: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'RAY/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Raydium',
    nameCn: null,
    nameKr: '레이디움',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/ray/symbol.png?raw=true',
    nftImageUrl: null,
    community: [],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'raydium',
    tvWidgetChartSymbol: 'RAYUSDT',
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: 'B2na8Awyd7cpC59iEU43FagJAPLigr3AP3s38KM982bu',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'USDT',
    baseMint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'USDT/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Tether',
    nameCn: null,
    nameKr: '테더',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
    nftImageUrl: null,
    community: [],
    chartType: 'KUCOIN',
    orderType: 'GENERAL',
    coingeckoId: 'tether',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: '3NnxQvDcZXputNMxaxsGvqiKpqgPfSYXpNigZNFcknmD',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MNGO',
    baseMint: 'MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'MNGO/USDC',
    mainCategory: 'DEX',
    subCategory: 'FINANCE',
    nameEn: 'Mango',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png',
    nftImageUrl: null,
    community: [
      {
        url: 'http://mango.markets',
        name: 'HOMEPAGE',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'mango-markets',
    tvWidgetChartSymbol: 'MNGOUSDT',
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: '9Lyhks5bQQxb9EyyX55NtgKQzpM4WK7JCmeaWuQ5MoXD',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MSOL',
    baseMint: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'MSOL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'DEFI',
    nameEn: 'Marinade staked SOL',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://assets.coingecko.com/coins/images/17752/small/PJ7Scn3.png?1629175951',
    nftImageUrl: null,
    community: [
      {
        url: 'https://marinade.finance',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/MarinadeFinance',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'marinade-staked-sol',
    tvWidgetChartSymbol: 'MSOLUSD',
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: '72h8rWaWwfPUL36PAFqyQZU8RT1V3FKG7Nc45aK89xTs',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RLB',
    baseMint: 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 2,
    quoteTokenDecimals: 6,
    symbol: 'RLB/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Rollbit Coin',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://rollbit.com/',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/rollbitcom',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'rollbit-coin',
    tvWidgetChartSymbol: null,
    isDexlabChart: false,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
]

export const MARKETS_TO_STRING = JSON.stringify(DEXLAB_MARKETS)
