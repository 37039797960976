import React, { useState } from 'react'
import { Result, Button, Modal } from 'antd'

export default function CreateCompleteMarket({ marketAddress }) {
  const [isModalVisible, setIsModalVisible] = useState(true)

  const showModal = () => {
    setIsModalVisible(!true)
  }
  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <div>
          <Result
            style={{ marginTop: '35px' }}
            status="success"
            title={'The market has been created.'}
            subTitle={'You can trade through Dexlab right now.'}
            extra={[
              <Button
                type="primary"
                key="telegram"
                onClick={() => {
                  window.location.href = `/#/market/${marketAddress}`
                }}
              >
                Go to Market
              </Button>,
              <Button
                type="primary"
                key="twitter"
                onClick={() => {
                  window.location.href = `/`
                }}
              >
                Dexlab OpenBook DEX
              </Button>,
            ]}
          />
        </div>
      </div>
      <Modal
        title={'Congratulations. The market is registered.'}
        closable={false}
        visible={isModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={showModal}
        okText="OK"
      >
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          If you have any questions about the market, please contact us through{' '}
          <a style={{ color: '#FFF' }} href="https://discord.gg/CyDrhAC7M4" target="_blank" rel="noopener noreferrer">
            Discord
          </a>
          .<br />
          It may take 1-3 days for transaction history recording to be activated.
          <br />
        </div>
      </Modal>
    </>
  )
}
