/* eslint-disable react-hooks/exhaustive-deps */
// import { useLocalStorageState } from './utils'
import { Account, AccountInfo, Connection, PublicKey, clusterApiUrl } from '@solana/web3.js'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { setCache, useAsyncData } from './fetch-loop'
import tuple from 'immutable-tuple'
import { ConnectionContextValues, EndpointInfo } from './types'
import { SOLANA_SERUM_HOST, DEXLAB_RPC_HOST, SERUM_ONLY_RPC_CONNECTION, RANDOM_RPC_CONNECTION } from '../application'

export const ENDPOINTS: EndpointInfo[] = [
  {
    name: 'dexlab-mainnet',
    endpoint: DEXLAB_RPC_HOST,
    custom: false,
  },
  {
    name: 'mainnet-beta-01',
    endpoint: SOLANA_SERUM_HOST,
    custom: false,
  },
]

const accountListenerCount = new Map()

const ConnectionContext: React.Context<null | ConnectionContextValues> =
  React.createContext<null | ConnectionContextValues>(null)

function getRpcHost() {
  if (!SERUM_ONLY_RPC_CONNECTION) {
    if (RANDOM_RPC_CONNECTION) {
      const getRpcServerId = Math.random()
      if (getRpcServerId < 0.5) {
        console.log(`Connection RPC: ${DEXLAB_RPC_HOST}`)
        return DEXLAB_RPC_HOST
      } else {
        console.log(`Connection RPC: ${SOLANA_SERUM_HOST}`)
        return SOLANA_SERUM_HOST
      }
    } else {
      console.log(`Connection RPC: ${DEXLAB_RPC_HOST}`)
      return DEXLAB_RPC_HOST
    }
  } else {
    return SOLANA_SERUM_HOST
  }
}

export function ConnectionProvider({ children }) {
  // const [endpoint, setEndpoint] = useLocalStorageState<string>('connectionEndpts', getRpcHost())
  // const [customEndpoints, setCustomEndpoints] = useLocalStorageState<EndpointInfo[]>('customConnectionEndpoints', [])
  const endpoint = getRpcHost()
  // const availableEndpoints = ENDPOINTS.concat(customEndpoints)

  const serumRpcConnection = useMemo(() => new Connection(endpoint, 'recent'), [endpoint])
  // const serumRpcSendConnection = useMemo(() => new Connection(endpoint, 'recent'), [endpoint])

  const dexlabRpcConnection = useMemo(() => new Connection(endpoint, 'recent'), [endpoint])

  // The websocket library solana/web3.js uses closes its websocket connection when the subscription list
  // is empty after opening its first time, preventing subsequent subscriptions from receiving responses.
  // This is a hack to prevent the list from every getting empty
  useEffect(() => {
    const id = serumRpcConnection.onAccountChange(new Account().publicKey, () => {})
    return () => {
      serumRpcConnection.removeAccountChangeListener(id)
    }
  }, [serumRpcConnection])

  useEffect(() => {
    const id = serumRpcConnection.onSlotChange(() => null)
    return () => {
      serumRpcConnection.removeSlotChangeListener(id)
    }
  }, [serumRpcConnection])

  // Dexlab RPC
  useEffect(() => {
    const id = dexlabRpcConnection.onAccountChange(new Account().publicKey, () => {})
    return () => {
      dexlabRpcConnection.removeAccountChangeListener(id)
    }
  }, [dexlabRpcConnection])

  useEffect(() => {
    const id = dexlabRpcConnection.onSlotChange(() => null)
    return () => {
      dexlabRpcConnection.removeSlotChangeListener(id)
    }
  }, [dexlabRpcConnection])

  return (
    <ConnectionContext.Provider
      value={{
        endpoint,
        // setEndpoint,
        connection: serumRpcConnection,
        sendConnection: serumRpcConnection,
        dexlabRpcConnection: dexlabRpcConnection,
        dexlabRpcSendConnection: serumRpcConnection,
        // availableEndpoints,
        // setCustomEndpoints,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  )
}

export function useConnection() {
  const context = useContext(ConnectionContext)
  if (!context) {
    throw new Error('Missing connection context')
  }
  return context.connection
}

export function useDexlabRpcConnection() {
  const context = useContext(ConnectionContext)
  if (!context) {
    throw new Error('Missing connection context')
  }
  return context.dexlabRpcConnection
}

export function useSolanaExplorerUrlSuffix() {
  const context = useContext(ConnectionContext)
  if (!context) {
    throw new Error('Missing connection context')
  }
  const endpoint = context.endpoint
  if (endpoint === clusterApiUrl('devnet')) {
    return '?cluster=devnet'
  } else if (endpoint === clusterApiUrl('testnet')) {
    return '?cluster=testnet'
  }
  return ''
}

export function useSendConnection() {
  const context = useContext(ConnectionContext)
  if (!context) {
    throw new Error('Missing connection context')
  }
  return context.sendConnection
}

export function useDexlabRpcSendConnection() {
  const context = useContext(ConnectionContext)
  if (!context) {
    throw new Error('Missing connection context')
  }
  return context.dexlabRpcSendConnection
}

export function useConnectionConfig() {
  const context = useContext(ConnectionContext)
  if (!context) {
    throw new Error('Missing connection context')
  }
  return {
    endpoint: context.endpoint,
    // endpointInfo: context.availableEndpoints.find((info) => info.endpoint === context.endpoint),
    // setEndpoint: context.setEndpoint,
    // availableEndpoints: context.availableEndpoints,
    // setCustomEndpoints: context.setCustomEndpoints,
  }
}

export function useAccountInfo(
  publicKey: PublicKey | undefined | null,
): [AccountInfo<Buffer> | null | undefined, boolean] {
  const connection = useConnection()
  const cacheKey = tuple(connection, publicKey?.toBase58())
  const [accountInfo, loaded] = useAsyncData<AccountInfo<Buffer> | null>(
    async () => (publicKey ? connection.getAccountInfo(publicKey) : null),
    cacheKey,
    { refreshInterval: 60_000 },
  )
  useEffect(() => {
    if (!publicKey) {
      return
    }
    if (accountListenerCount.has(cacheKey)) {
      let currentItem = accountListenerCount.get(cacheKey)
      ++currentItem.count
    } else {
      let previousInfo: AccountInfo<Buffer> | null = null
      const subscriptionId = connection.onAccountChange(publicKey, (info) => {
        if (!previousInfo || !previousInfo.data.equals(info.data) || previousInfo.lamports !== info.lamports) {
          previousInfo = info
          setCache(cacheKey, info)
        }
      })
      accountListenerCount.set(cacheKey, { count: 1, subscriptionId })
    }
    return () => {
      let currentItem = accountListenerCount.get(cacheKey)
      let nextCount = currentItem.count - 1
      if (nextCount <= 0) {
        connection.removeAccountChangeListener(currentItem.subscriptionId)
        accountListenerCount.delete(cacheKey)
      } else {
        --currentItem.count
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey])
  const previousInfoRef = useRef<AccountInfo<Buffer> | null | undefined>(null)
  if (
    !accountInfo ||
    !previousInfoRef.current ||
    !previousInfoRef.current.data.equals(accountInfo.data) ||
    previousInfoRef.current.lamports !== accountInfo.lamports
  ) {
    previousInfoRef.current = accountInfo
  }
  return [previousInfoRef.current, loaded]
}

export function useAccountData(publicKey) {
  const [accountInfo] = useAccountInfo(publicKey)
  return accountInfo && accountInfo.data
}

export function setInitialAccountInfo(connection, publicKey, accountInfo) {
  const cacheKey = tuple(connection, publicKey.toBase58())
  setCache(cacheKey, accountInfo, { initializeOnly: true })
}
