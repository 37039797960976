import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Wallet from '@project-serum/sol-wallet-adapter'
// import { WalletAdapter } from '@solana/wallet-adapter-base'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom'
import { createAssociatedTokenAccount as serumCreateAssociatedTokenAccount } from '@project-serum/associated-token'
import { sendTransaction } from '../utils/send'
import { notify } from './notifications'
import { useConnectionConfig } from './connection'
import { useLocalStorageState } from './utils'
import { WalletContextValues } from './types'
import {
  makeNativeTransferTransaction,
  transferTokensOrCreateBaseMintWallet,
  signAndSendTransaction,
} from '../utils/tokens/index'
import { memoInstruction } from '../utils/tokens/instructions'
import { Button, Modal } from 'antd'
import {
  WalletAdapter,
  LedgerWalletAdapter,
  MathWalletAdapter,
  // SolletExtensionAdapter,
  SolflareExtensionWalletAdapter,
  Coin98WalletAdapter,
} from '../wallet-adapters'
import { Transaction } from '@solana/web3.js'

const ASSET_URL = 'https://cdn.jsdelivr.net/gh/solana-labs/oyster@main/assets/wallets'
export const WALLET_PROVIDERS = [
  // {
  //   name: 'Sollet Extension',
  //   url: 'https://www.sollet.io/extension',
  //   icon: `${ASSET_URL}/sollet.svg`,
  //   adapter: SolletExtensionAdapter as any,
  // },
  {
    name: 'Sollet for Dexlab',
    url: 'https://sollet.dexlab.space',
    icon: `https://raw.githubusercontent.com/dexlab-project/assets/master/dexlab/dexlab_symbol_logo.svg`,
  },
  {
    name: 'Solflare',
    url: 'https://solflare.com/access-wallet',
    icon: `${ASSET_URL}/solflare.svg`,
  },
  {
    name: 'Solflare Extension',
    url: 'https://solflare.com',
    icon: `${ASSET_URL}/solflare.svg`,
    adapter: SolflareExtensionWalletAdapter,
  },
  {
    name: 'Coin98',
    url: 'https://www.coin98.com',
    icon: `https://wallet.coin98.com/static/media/iconCoin98White.d3e66800.svg`,
    adapter: Coin98WalletAdapter,
  },
  {
    name: 'Phantom',
    url: 'https://www.phantom.app',
    icon: `https://www.phantom.app/img/logo.png`,
    adapter: PhantomWalletAdapter,
  },
  {
    name: 'Ledger',
    url: 'https://www.ledger.com',
    icon: `${ASSET_URL}/ledger.svg`,
    adapter: LedgerWalletAdapter,
  },
  {
    name: 'MathWallet',
    url: 'https://www.mathwallet.org',
    icon: `${ASSET_URL}/mathwallet.svg`,
    adapter: MathWalletAdapter,
  },
  // {
  //   name: 'sollet.io',
  //   url: 'https://www.sollet.io',
  //   icon: `${ASSET_URL}/sollet.svg`,
  // },
  // {
  //   name: 'Solong',
  //   url: 'https://www.solong.com',
  //   icon: `${ASSET_URL}/solong.png`,
  //   adapter: SolongWalletAdapter,
  // },
]

const WalletContext = React.createContext<null | WalletContextValues>(null)

export function WalletProvider({ children }) {
  const { endpoint } = useConnectionConfig()

  const [autoConnect, setAutoConnect] = useState(false)
  const [providerUrl, setProviderUrl] = useLocalStorageState('walletProvider', 'https://sollet.dexlab.space')

  const provider = useMemo(() => WALLET_PROVIDERS.find(({ url }) => url === providerUrl), [providerUrl])

  let [wallet, setWallet] = useState<WalletAdapter | undefined>(undefined)

  useEffect(() => {
    if (provider) {
      const updateWallet = () => {
        // hack to also update wallet synchronously in case it disconnects
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wallet = new (provider.adapter || Wallet)(providerUrl, endpoint) as WalletAdapter
        setWallet(wallet)
      }

      if (document.readyState !== 'complete') {
        // wait to ensure that browser extensions are loaded
        const listener = () => {
          updateWallet()
          window.removeEventListener('load', listener)
        }
        window.addEventListener('load', listener)
        return () => window.removeEventListener('load', listener)
      } else {
        updateWallet()
      }
    }
  }, [provider, providerUrl, endpoint])

  const [connected, setConnected] = useState(false)

  useEffect(() => {
    if (wallet) {
      wallet.on('connect', () => {
        if (wallet?.publicKey) {
          console.log('connected')
          localStorage.removeItem('feeDiscountKey')
          setConnected(true)
          const walletPublicKey = wallet.publicKey.toBase58()
          const keyToDisplay =
            walletPublicKey.length > 20
              ? `${walletPublicKey.substring(0, 7)}.....${walletPublicKey.substring(
                  walletPublicKey.length - 7,
                  walletPublicKey.length,
                )}`
              : walletPublicKey

          notify({
            message: 'Wallet update',
            description: 'Connected to wallet ' + keyToDisplay,
          })
        }
      })

      wallet.on('disconnect', () => {
        setConnected(false)
        notify({
          message: 'Wallet update',
          description: 'Disconnected from wallet',
        })
        localStorage.removeItem('feeDiscountKey')
      })
    }

    return () => {
      setConnected(false)
      if (wallet && wallet.connected) {
        wallet.disconnect()
        setConnected(false)
      }
    }
  }, [wallet])

  useEffect(() => {
    if (wallet && autoConnect) {
      wallet.connect()
      setAutoConnect(false)
    }

    return () => {}
  }, [wallet, autoConnect])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const select = useCallback(() => setIsModalVisible(true), [])
  const close = useCallback(() => setIsModalVisible(false), [])

  return (
    <WalletContext.Provider
      value={{
        wallet: wallet ? (wallet as WalletAdapter) : undefined,
        connected,
        select,
        providerUrl,
        setProviderUrl,
        providerName: WALLET_PROVIDERS.find(({ url }) => url === providerUrl)?.name ?? providerUrl,
      }}
    >
      {children}
      <Modal
        style={{ backgroundColor: '#000' }}
        title="Select Wallet"
        okText="Connect"
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={isModalVisible}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={close}
        width={500}
      >
        {/* <div style={{ marginBottom: '6px' }}>
          <Alert
            message={
              <>
                Currently, some transactions are getting permission errors after the phantom wallet 0.13.0 update.
                <br />
                Phantom wallet users, please contact us through{' '}
                <a href="https://help.phantom.app/hc/en-us" rel="noopener noreferrer" target="_blank">
                  phantom support
                </a>
              </>
            }
            type="warning"
          />
        </div> */}
        {WALLET_PROVIDERS.map((provider, idx) => {
          const onClick = function () {
            setProviderUrl(provider.url)
            setAutoConnect(true)
            close()
          }

          return (
            <Button
              key={idx}
              size="large"
              onClick={onClick}
              icon={
                <img alt={`${provider.name}`} width={20} height={20} src={provider.icon} style={{ marginRight: 8 }} />
              }
              style={{
                display: 'block',
                height: '60px',
                width: '100%',
                textAlign: 'left',
                marginBottom: 8,
              }}
            >
              {provider.name}
            </Button>
          )
        })}
      </Modal>
    </WalletContext.Provider>
  )
}

export function useWallet() {
  const context = useContext(WalletContext)
  if (!context) {
    throw new Error('Missing wallet context')
  }

  const wallet = context.wallet
  return {
    connected: context.connected,
    wallet: wallet,
    providerUrl: context.providerUrl,
    setProvider: context.setProviderUrl,
    providerName: context.providerName,
    select: context.select,
    connect() {
      wallet ? wallet.connect() : context.select()
    },
    disconnect() {
      wallet?.disconnect()
    },
  }
}

/**
 * 토큰지갑 생성 트렌젝션
 * @param param0
 * @returns
 */
export async function makeTokenWalletTransaction({ wallet, splTokenMintAddress }) {
  const transaction = new Transaction()
  transaction.add(await serumCreateAssociatedTokenAccount(wallet.publicKey, wallet.publicKey, splTokenMintAddress))
  return transaction
}

/**
 * 메모 트랜잭션을 통해 소유자를 검증한다
 * @param param0
 */
export async function requestOwnerVerifiedTransactionForMemo({ connection, wallet, verifiedCode, marketAddress }) {
  const transaction = new Transaction()
  transaction.add(memoInstruction(verifiedCode))
  transaction.add(memoInstruction(`DEXLAB ADD MARKET: (${marketAddress})`))
  notify({ message: 'Sending transaction...' })
  const txId = await signAndSendTransaction(connection, transaction, wallet, [])
  notify({ message: 'Transaction sent', type: 'success', txid: txId })

  // 컨펌요청

  // 컨펌완료
  notify({ message: 'Transaction confirmed', type: 'success', txid: txId })
  return txId
}

/**
 * 토큰을 다른 지갑으로 전송
 * @param param0
 * @returns
 */
export async function transferToken({
  wallet,
  connection,
  source,
  toPubkey,
  baseTokenMint,
  amount,
  memo,
  mint,
  decimals,
  overrideDestinationCheck,
  isCreateWallet,
}) {
  if (source.equals(wallet?.publicKey)) {
    const transaction = await makeNativeTransferTransaction(source, toPubkey, amount)

    if (memo) {
      transaction.add(memoInstruction(memo))
    }

    // IDO 토큰 지갑이 없는 경우 생성
    if (isCreateWallet && baseTokenMint) {
      transaction.add(await makeTokenWalletTransaction({ wallet, splTokenMintAddress: baseTokenMint }))
    }
    const txId = await sendTransaction({ connection, wallet, transaction, signers: [] })
    return txId
  }
  // SPL TOKEN
  return await transferTokensOrCreateBaseMintWallet({
    connection,
    owner: wallet,
    sourcePublicKey: source,
    destinationPublicKey: toPubkey,
    amount,
    memo,
    mint,
    decimals,
    baseTokenMint,
    overrideDestinationCheck,
    isCreateWallet,
  })
}
