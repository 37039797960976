/* eslint-disable */
import React from 'react'
import { Statistic, Card, Row, Col, Descriptions, Tag } from 'antd'

export default function LpPoolInfoSummary({ lpPool }) {
  return (
    <>
      <div style={{ margin: '10px' }} className="row">
        <div className={`col-xl-12 col-lg-12 col-md-12`}>
          <div className="site-statistic-demo-card" style={{ marginTop: '10px' }}>
            <Row gutter={16}>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Liquidity"
                    value={lpPool.liquidity}
                    precision={4}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={'$'}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Volume(24h)"
                    value={lpPool.volume}
                    precision={4}
                    valueStyle={{ color: '#cf1322' }}
                    prefix={'$'}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="APY"
                    value={lpPool.apy}
                    precision={4}
                    valueStyle={{ color: '#cf1322' }}
                    suffix={'%'}
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <Descriptions title="Pool Infomation" layout="vertical" bordered style={{ marginTop: '60px' }}>
            <Descriptions.Item label="Name">{lpPool.name}</Descriptions.Item>
            <Descriptions.Item label="Amm ID">
              <a
                style={{ color: '#FFFFFF' }}
                href={`https://www.raydium.io/swap/?ammId=${lpPool.ammId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lpPool.ammId}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Pool" style={{ textAlign: 'center' }}>
              {lpPool.official ? (
                <Tag color="success">Raydium Pools</Tag>
              ) : (
                <Tag color="warning">Permissionless Pools</Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
          <div style={{ textAlign: 'right', marginTop: '12px' }}>
            <span style={{ fontSize: '11px', fontStyle: 'italic' }}>powered by </span>
            <img
              style={{ height: '30px', marginRight: '6px', marginLeft: '6px' }}
              src={require('../images/raydium_logo.svg')}
            />
          </div>
        </div>
      </div>
    </>
  )
}
