import React, { useEffect } from 'react'

const GoogleAdvertise = ({
  className = 'adsbygoogle',
  client = '',
  slot = '',
  format = '',
  responsive = '',
  layoutKey = '',
}) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production')
      try {
        /* @ts-ignore */
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
        console.log('Advertise is pushed')
      } catch (e) {
        console.error('AdvertiseError', e)
      }
  }, [])

  //production이 아닌 경우 대체 컴포넌트 표시
  if (process.env.NODE_ENV !== 'production')
    return (
      <div
        style={{
          background: '#00000000',
          color: '#ffffff',
          fontSize: '18px',
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '16px',
        }}
      >
        Ads
      </div>
    )
  //production인 경우 구글 광고 표시
  return (
    <ins
      className={className}
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'block',
        textAlign: 'center',
      }}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={responsive}
      data-ad-layout-key={layoutKey}
    />
  )
}

export default GoogleAdvertise
